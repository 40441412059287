import { createI18n } from 'vue-i18n';

const messages = {
  ru: {
    header:{
      currentLanguage: 'RU',
      navigation:{
        company:'Компания',
        projects:'Проекты',
        contact:'Контакты'
      },
      company:{
        about:'О нас',
        blog:'Блог',
        career:'Карьера',
      },
      projects:{
        rides: 'Поездки',
        driver: 'Водитель',
        hamroh: 'Hamroh'
      }
    },
    footer:{
      companyText:{
        company: 'Компания',
        about: 'О нас',
        blog: 'Блог',
        career: 'Карьера',
        contacts: 'Контакты',
        projects:'Проекты',
        rides:'Поездка',
        driver:'Водитель',
        hamroh:'Hamroh',
        switchBtn:'Русский'
      }
    },
    home: {
      ride: 'Поездки',
      delivery: 'Доставки',
      driver: 'Водитель',
      formMainText: {
        ride: 'Заказать поездку сейчас',
        driver: 'Стань водителем и начни зарабатывать',
        delivery: 'C deliGram доставка быстрее'
      },
      buttonText: {
        first: 'Зарегистрироваться',
        second: 'Заказать'
      },
      placeholders:{
        addFirst: 'Откуда поедете? ',
        addSecond: 'Куда поедете?'
      },
      priceText: {
        price: 'Цена',
        from : 'от'
      },
      formSecondText: {
        deliver: 'Закажите все что вам нужно в один клик',
        driver: 'Станьте частью нашей команды Gram'
      },
      deliGramRide: {
        ride:'Быстрые и доступные поездки',
        info:'Закажите такси Gram через сайт или приложение и наслаждайтесь поездкой.'
      },
      buttonRide: {
        order: 'Заказать',
        registration:'Зарегистрироваться'
      },
      fastEasy: {
        text: 'Быстро и просто',
        info: 'Подача за считанные минуты'
      },
      prices: {
        text: 'Отличные цены',
        info: 'Мы стремимся предлагать лучшие цены на поездки в вашем городе'
      },
      comfort: {
        text: 'Удобно',
        info: 'Доберитесь куда угодно - всего за пару кликов'
      },
      optimize: {
        text: 'Оптимизируйте свою работу',
        info: 'Скачайте приложение для быстрого доступа к заказам!'
      },
      driverDownload: {
        text: 'Скачать приложение для водителей'
      },
      clientDownload: {
        text: 'Скачать приложение для пассажиров'
      },
      aboutUs: {
        text:'О нас',
        info: 'Узнайте, как мы начинали, что нами движет и куда мы стремимся.',
        link: 'Узнайте больше о Gram '
      },
      blog: {
        text:'Блог',
        info: 'Следите за новостями о наших новых проектах и достижениях.',
        link: 'Перейти в отдел новостей'
      },
      career: {
        text:'Карьера',
        info: 'Присоединяйтесь к нашей команде и развивайтесь вместе с нами.',
        link: 'Узнать о доступных вакансиях'
      },
      gramBusines:{
        text:'Gram для бизнеса',
        info:'Продвигайте свой бизнес вперед вместе с Gram',
        button: 'Скоро'
      },
      gramButton: {
        text1: 'Начните зарабатывать с Gram',
        text2: 'Закажи такси'
      },
      deliText: {
        text: 'Экономьте время с deliGram',
        info: 'Доставка поможет быстро передать забытую вещь, отвезти/забрать документы или привезти вкусную еду.'
      },
      findJob: {
        text: 'Найдите работу по душе',
        info: 'Станьте водителем и зарабатывайте от 100 смн в день.'
      },
      infoDelivery:{
        title:'Быстро и просто',
        text:'Доставка от 15 минут',
        title1:'Отличные цены',
        text1:'Закажите доставку из любой точки по оптимальной цене',
        title2:'Удобно',
        text2:'Закажите еду, продукты или лекарства в любое время',
      }
    },
    driverPage:{
      findJob:{
        text1:'Найдите работу по душе',
        text2:'Возить людей или доставлять посылки и грузы - пешком или на автомобиле',
        text3:'Зарабатывайте',
        text4:'от 100 смн в день',
      },
      buttonText:{
        text: 'Зарегистрироваться',
        text2: 'Скачать',
        text3: 'Начать зарабатывать',
        text4: 'скачать',
        text5:'Скачать приложение',
        text6: 'Начните зарабатывать с Gram Traffic',
      },
      driverText:{
        text: 'Выгодные условия',
        text1:'Быстрый старт',
        text2:'Всего несколько часов от анкеты до первого заказа',
        text3:'Гибкий график',
        text4:'Работайте несколько часов в день или полную смену',
        text5:'Работайте на себя',
        text6:'Станьте самозанятым чтобы не платить лишние комиссии',
        text7:'Деньги сразу',
        text8:'Оплату за наличные заказы вы получите в первый день работы',
        text9:'Самая низкая комиссия',
        text10:'Комиссия составляет всего 10 %',
        text11:'Заказы по вашему выбору',
        text12:'Вы сами решаете какие заказы брать - совмещать доставку с такси или нет',
      },
      downApp:{
        text1:'Скачайте приложение Gram Traffic ',
        text2:'Приложение Gram дает вам возможность добраться туда, куда вы хотите.'
      },
      calculate:{
        text1:'Калькулятор дохода',
        text2:'Выберите тариф',
        text3:'Выберите топливо',
        text4:'Расход топлива на 100км',
        text5:'Сколько часов в день планируете работать?',
        text6:'Сколько дней в месяц планируете работать? ',
        text7:'в месяц или',
        text8:'с. в час',
      },
      workTree:{
        text1:'Как начать работу в Gram?',
        text2:'Зарегистрируйтесь',
        text3:'Перейдите на сайт и пройдите регистрацию',
        text:'СМС',
        text4:'Получите свой логин и пароль',
        text5:'Баланс',
        text6:'Пополните баланс приложение',
        text7:'Начинайте зарабатывать',
        text8:'Начните получать заказы и зарабатывать',
        text9:'регистрацию',
        text10:'Перейдите на сайт и пройдите ',
      },
      optimize:{
        text1:'Оптимизируйте свою работу',
        text2:'Скачайте приложение для быстрого доступа к заказам!',
        text3:'Скачать приложение для водителей'
      },
      questions:{
        text:'Часто задаваемые вопросы',
        text1:'Как я могу стать водителем такси?',
        text2:'Для того, чтобы стать водителем такси, необходимо иметь водительское удостоверение категории В и наличие собственного автомобиля.',
        text3:'Каким образом я буду получать заказы на перевозку пассажиров?',
        text4:'Для получения заказов, нужно ',
        text9:'приложение Gram Traffic. Там будут выводится ближайшие к вам заказы.',
        text5:'Как я буду получать оплату за перевозки?',
        text6:'Вы будете получать оплату за поездку напрямую от клиента. Можно принимать оплату наличными или же через платежные системы.',
        text7:'Я не профессиональный водитель - смогу ли я работать в такси?',
        text8:'Выполнять заказы Gram могут водители старше 18 лет.Новичкам мы расскажем обо всех особенностях и стандартах качества нашего сервиса. Во всём остальном поможет Gram Traffic: найдёт заказ и проложит маршрут с учётом пробок и перекрытий.',
      }
    },
    aboutUs:{
      textAbout:{
        text1:'О нас',
        text2:'Мы переосмысливаем то, как движется мир',
        text3:'Мы – Gram. Мы – новаторы. Мы – команда, неустанно стремящаяся помочь людям достичь своих целей, приобрести необходимые вещи и заработать на жизнь. Мобильность – наш основа, ежедневно вдохновляющий нас на улучшение доступа к передвижению.',
        text4: 'Как технологическая компания, мы объединяем цифровой и физический мир, обеспечивая легкость перемещения одним касанием. Мы видим мир, где мобильность доступна всем, обеспечивая безопасность и устойчивость для каждого, независимо от его личности. Неудачи для нас – это катализатор роста, укрепляющий нашу приверженность обслуживанию клиентов, сообществ, городов и партнеров по всему миру.',
        text5: 'История Gram началась в Худжанде в 2016 году, и с тех пор инновации и адаптация стали частью нашей культуры. Мы выросли в платформу, предоставляющую возможности для гибкого заработка и транспортировки людей и товаров разнообразными способами. От объединения поездок на различных видах транспорта до упрощения грузоперевозок, от доставки еды и предметов первой необходимости до лекарств – мы круглосуточно удовлетворяем ваши потребности, предоставляя возможности для заработка.',
        text6: 'Безопасность остается нашим главным приоритетом благодаря тщательной проверке анкетных данных и процессам проверки в реальном времени. В Gram стремление к инновациям не знает границ – это постоянный путь открытий и переосмыслений.'
      },
      whoWeAre:{
        title1:'Кто мы?',
        text1:'Мы молодое цифровое поколение будущего, мы новаторы, созидатели, бунтари, готовые брать на себя ответственность за решение задачи и подрывать статус-кво.',
        title2:'Что мы делаем?',
        text2:'Основываясь на потребительской опыт и миллиарды цифр, разрабатываем инновационные продукты, которые делают нашу жизнь и жизнь наших клиентов удобнее быстрее и доступнее.',
        title3:'Для кого?',
        text3:'Один из наших принципов это быть доступными для всех, кто хочет получить качественный сервис по выгодным условиям. Мы создаем продукты для нашей страны, общества, всех наших клиентов.',
        title4:'Миссия',
        text4:'Наша миссия — создавать инновационные технологии для обеспечения качественного и быстрого сервиса, способствующего развитию общества и улучшению жизни людей, стремясь стать лидером в коммуникации, логистике и перевозках.',
        title5:'Видение',
        text5:'Стать самой клиентоориентированной компанией 21-го века, которая предоставляет доступный сервис по перевозкам.',
        title6:'Ценности',
        text6_1:'Жизнь это движение ежедневно мы двигаемся к своей цели используя инновационные инструменты, которые экономят время и открывают доступ к сотням сервисам.',
        text6_2:'Результат за короткие сроки и при минимальных затратах максимально добиться поставленных целях!',
        text6_3:'1-1=2 не боятся делать хорошее каким бы ни был ответ.',
        title7:'Наша цель',
        text7:'Создать международный бренд в сфере перевозки и стать клиентоориентированным сервисом до 2030 года.',
      },
      ourTeam:{
        title: 'Наша команда',
        text1:'Наша команда это команда профессионалов, объединенных общей целью и поставленными задачами. Мы работаем в тесном единстве, придавая важность каждому мнению и идее. Мы придерживаемся принципа доверия и понимания, помогая друг другу.',
        text2:'У нас работают лучшие специалисты самых разных профессий. Самому молодому сотруднику в нашей компании 17 лет. Для нас главное не возраст и опыт работы, а ваши амбиции и желание развиваться.',
        text3:'Непрерывное развитее залог успеха Мы идём в ногу со временем: участвуем в хакатонах, экспериментируем стартапы и проводим интереснейшие тимбилдинги для наших ребят. Мы умеем не только работать, но и отдыхать от повседневной работы.',
      },
      searchJob:{
        title:'Найди работу по душе',
        text:'Поиск доступных вакансий'
      }
    },
    blogPage:{
      title1:'Блог',
      title2:'Наш новый проект - Hamroh',
      text:'Куда бы вы не собирались, в какой бы удаленный населенный пункт не ехали с Hamroh каждая поездка будет безопасной. Вы всегда доедете быстро, удобно и выгодно!',
      button:'Узнать больше'
    },
    ridePage:{
      title1:'Всегда та поездка,',
      title2:'которую вы хотите',
      text:'Закажите поездку, садитесь и расслабьтесь.',
      button:'Заказать',
        infoBlock:{
          title:'Зачем использовать приложение Gram?',
          title1:'Быстро и просто',
          text1:'Подача за считанные минуты',
          title2:'Отличные цены',
          text2:'Мы стремимся предлагать лучшие цены на поездки в вашем городе',
          title3:'Удобно',
          text3:'Доберитесь куда угодно всего в пару кликов',
        },
        downloadGram:{
          title:'Скачайте приложение Gram',
          text:'Приложение Gram дает вам возможность добраться туда, куда вы хотите.',
          button:'Скачать'
        },
        questionsBlock:{
          title:'Часто задаваемые вопросы',
          ques1:'Как создать аккаунт для заказа такси?',
          answer1:'Для создание аккаунта вам нужно скачать приложение Gram из Google Play, или зарегистрироватся на сайте ',
          ques2:'Как заказать поездку?',
          answer2:'Откройте приложение или войдите на сайт ',
          answer3:'и введите пуникт назначения. Затем выберите вариант поездки, который лучше всего соответствует вышим потребностям. Подтвердите заказ, нажав "Заказать"',
          ques3:'Могу ли я заказть такси без мобильного приложения?',
          answer4:'Да, вы можете заказать такси через наш сайт ',
          answer5:' или позвонив на наш короткий номер 0220.',
        },
        contactPage:{
          text:'Таджикистан, Худжанд, проспект И.Сомони 46а'
        }
    },
  },
  tj: {
    header:{
      currentLanguage: 'TJ',
      navigation:{
        company:'Ширкат',
        projects:'Лоиҳа',
        contact:'Тамос'
      },
      company:{
        about:'Дар бораи мо',
        blog:'Блог',
        career:'Ҷои кор',
      },
      projects:{
        rides: 'Сафарҳо',
        driver: 'Ронанда',
        hamroh: 'Hamroh'
      }
    },
    footer:{
      companyText:{
        company: 'Ширкат',
        about: 'Дар бораи мо',
        blog: 'Блог',
        career: 'Ҷои кор',
        contacts: 'Тамос',
        projects:'Проектҳо',
        rides:'Сафарҳо',
        driver:'Ронанда',
        hamroh:'Hamroh',
        switchBtn:'Тоҷики'
      }
    },
    home: {
      ride: 'Сафарҳо',
      delivery: 'Дастрасткуни',
      driver: 'Ронанда',
      formMainText: {
        ride: 'Фармоиши сафар',
        driver: 'Ронанда шавед ва даромад гирифтанро сар кунед',
        delivery: 'Бо deliGram дастрастнамои тезтар'
      },
      buttonText: {
        first: 'Ба қайд гирифтан ',
        second: 'Фармоиш додан'
      },
      placeholders:{
        addFirst: 'Аз кучо меравед?',
        addSecond: 'Ба кучо меравед?'
      },
      priceText: {
        price: 'Нарх',
        from : 'аз'
      },
      formSecondText: {
        deliver: 'Чизхои лозимаатонро бо як пахшкуни фармоиш диҳед',
        driver: 'Як қисми дастаи Gram шавед'
      },
      deliGramRide: {
        ride:'Сафарҳои зуд ва дастрас',
        info:'Такси Gram-ро тавассути сомона ё барнома фармоиш диҳед ва аз сафар лаззат баред.'
      },
      buttonRide: {
        order: 'Фармоиш додан',
        registration:'Ба қайд гирифтан '
      },
      fastEasy: {
        text: 'Тез ва осон',
        info: 'Иҷроиши фармоиш дар якчанд дақиқа'
      },
      prices: {
        text: 'Нархҳои аъло',
        info: 'Мо кушиш мекунем, ки бехтарин нарххоро барои сафар дар шахри шумо пешниход кунем'
      },
      comfort: {
        text: 'Қулай',
        info: 'Бо якчанд пахш - ба ҷои лозимиатон расед '
      },
      optimize: {
        text: 'Кори худро бехтар кунед',
        info: 'моишҳо барномаро насб намоед!'
      },
      driverDownload: {
        text: 'Барномаи ронандагонро насб намоед'
      },
      clientDownload: {
        text: 'Барномаи мусофиронро насб намоед'
      },
      aboutUs: {
        text:'Дар бораи мо',
        info: 'Шинос шавед, ки мо чӣ тавр оғоз кардем, чӣ моро ҳавасманд мекунад ва мо чӣ мақсад дорем.',
        link: 'Маълумоти бештар дар бораи Gram '
      },
      blog: {
        text:'Блог',
        info: 'Дар бораи лоиҳаҳо ва дастовардҳои нави мо хабардор шавед.',
        link: 'Гузаштан ба сахифа'
      },
      career: {
        text:'Ҷои кор',
        info: 'Ба дастаи мо ҳамроҳ шавед ва бо мо рушд кунед.',
        link: 'Маълумот дар бораи ҷойҳои корӣ'
      },
      gramBusines:{
        text:'Gram барои бизнес',
        info:'Бизнеси худро бо Gram пеш баред',
        button: 'Дар оянда'
      },
      gramButton: {
        text1: 'Бо Gram даромад гирифтанро оғоз кунед',
        text2: 'Таксӣ фармоиш диҳед'
      },
      deliText: {
        text: 'Бо deliGram вақтро сарфа кунед',
        info: 'Дастраскуни ба шумо кӯмак мекунад, ки ашёи фаромӯшшударо зуд расонед, ҳуҷҷатҳоро фиристед/гиред ё ғизои лазиз биёред.'
      },
      findJob: {
        text: 'Кори дӯстдоштаро пайдо кунед',
        info: 'Ронанда шавед ва аз 100 см дар як руз даромад гиред. '
      },
      infoDelivery:{
        title:'Тез ва осон',
        text:'Дастраскуни аз 15 дақиқа',
        title1:'Нархҳои аъло',
        text1:'Аз ҳама ҷо бо нархи беҳтарин фармоиш диҳед',
        title2:'Кулай',
        text2:'Дар вақти дилхоҳ хӯрок, хӯрокворӣ ё дору фармоиш диҳед вақт',
      }
    },
    driverPage:{
      findJob:{
        text1:'Кори дӯстдоштаро пайдо кунед',
        text2:'Кашондани мусофирон ё бастаҳо ва борҳо - пиеда ё бо мошин',
        text3:'Аз 100см',
        text4:'дар як рӯз даромад гиред ',
      },
      buttonText:{
        text: 'Ба қайд гирифтан',
        text2: 'Насб кардан',
        text3: 'Даромад гирифтанро оғоз кардан',
        text4: 'насб кардан',
        text5: 'Барномаро насб кардан',
        text6: 'Бо Traffic Gram даромад гирифтанро оғоз кунед',
      },
      driverText:{
        text: 'Шартҳои муфид ',
        text1:'Оғози зуд',
        text2:'Ҳамагӣ якчанд соат аз саволнома то фармоиши аввал',
        text3:'Реҷаи озоди кор ',
        text4:'Якчанд соат дар як рӯз ё сменаи пурра кор кунед',
        text5:'Худсарона кор кунед',
        text6:'Бе пардохти комиссия мустақилона кор кунед',
        text7:'Пардохти нақди пешаки',
        text8:'Пардохти нақди фармоишҳоро дар рӯзи аввали кор мегиред',
        text9:'Бе хароҷоти иловагӣ ',
        text10:'Комиссия ҳамагӣ 10% аст',
        text11:'Фармоишҳо аз рӯйи интихоби шумо',
        text12:'Шумо худатон қарор медиҳед, ки кадом фармоишҳоро мегиред - дастраскуниро бо такси ҳамроҳ  кунед ё не',
      },
      downApp:{
        text1:'Барномаи Gram Traffic-ро насб намоед ',
        text2:'Барномаи Gram ба Шумо имконияти ба ҷои хостаатон рафтанро медиҳад.'
      },
      calculate:{
        text1:'Ҳисобкунаки даромад',
        text2:'Тарофаро интихоб кунед',
        text3:'Сӯзишвориро интихоб кунед',
        text4:'Сарфи сӯзишворӣ дар 100 км',
        text5:'Шумо дар як рӯз чанд соат кор мекунед?',
        text6:'Шумо дар як моҳ чанд рӯз кор мекунед? ',
        text7:'Дар як моҳ ё',
        text8:'с. дар як соат',
      },
      workTree:{
        text1:'Чӣ тавр дар Gram бояд ба кор оғоз намуд?',
        text2:'Ба қайд гиред ',
        text3:'Ба сомона гузаред ва бақайдгириро гузаред',
        text:'СМС',
        text4:'Логин ва пароли худро гиред',
        text5:'Тавозун',
        text6:'Тавозуни барномаро пур кунед',
        text7:'Даромад гирифтанро оғоз намоед',
        text8:'Қабули фармоишҳо ва даромад гифтанро сар кунед',
        text9:'бақайдгириро гузаред',
        text10:'Ба сомона гузаред ва',
      },
      optimize:{
        text1:'Кори худро бехтар кунед',
        text2:'Барои фармоиши зуд барномаро насб кунед!',
        text3:'Барномаи ронандагонро насб намоед'
      },
      questions:{
        text:'Саволҳои маъмул',
        text1:'Чӣ тавр ман метавонам ронандаи таксӣ шавам?',
        text2:'Барои ронандаи таксӣ шудан, бояд шаҳодатномаи ронандагии категорияи В ва мошини шахсии худро дошта бошед.',
        text3:'Чӣ тавр ман фармоишҳоро барои кашонидани мусофирон қабул мекунам?',
        text4:'Барои гирифтани фармоишҳо, барномаи Gram Traffic -ро ',
        text9:'намудан лозим аст. Фармоишҳои ба шумо наздиктарин дар он ҷо нишон дода мешаванд.',
        text5:'Чӣ тавр ман пардохт барои иҷрои фармоишро мегирам?',
        text6:'Шумо пардохти сафарро аз клиент мегиред. Шумо метавонед пардохтро бо пули нақд ё тавассути системаҳои пардохт қабул кунед.',
        text7:'Ман ронандаи касбӣ нестам - оё ман метавонам дар таксӣ кор кунам?',
        text8:'Ронандагони аз 18-сола боло метавонанд фармоишҳои Gram-ро иҷро кунанд. Мо ба навомадагон дар бораи тамоми хусусиятҳо ва стандартҳои сифати хидматрасонии мо нақл мекунем. Gram Traffic дар ҳама чизи дигар ёрӣ медиҳад: фармоишро меёбад ва маршрутро бо дарназардошти роҳбандӣ  месозад.',
      }
    },
    aboutUs:{
      textAbout:{
        text1:'Дар бораи мо',
        text2:'Мо тарзи ҳаракати ҷаҳонро аз нав тасаввур мекунем.',
        text3:'Мо Gram ҳастем. Мо навоварем. Мо як гурӯҳе ҳастем, ки пайваста мекӯшем, ки ба одамон дар расидан ба ҳадафҳои худ, ба даст овардани чизҳои зарурӣ ва зиндагӣ кӯмак расонем. Ҳаракат асоси мост, ки ҳамарӯза моро барои беҳтар кардани дастрасӣ ба ҳаракат илҳом мебахшад.',
        text4: 'Ҳамчун як ширкати технологӣ, мо ҷаҳони рақамӣ ва ҷисмониро муттаҳид карда, ҳаракати осонро бо як ламс таъмин мекунем. Мо ҷаҳонеро мебинем, ки ҳаракат барои ҳама дастрас аст ва барои ҳама, новобаста аз шахсияти онҳо, амният ва устуворӣ фароҳам меорад. Нокомиҳо барои мо катализатори рушд мебошанд, ки ӯҳдадориҳои моро ба хизматрасонӣ ба мизоҷон, ҷомеаҳо, шаҳрҳо ва шарикон дар саросари ҷаҳон тақвият медиҳанд.',
        text5: 'Таърихи Gram дар Хуҷанд соли 2016 оғоз ефт ва аз он вақт инҷониб навоварӣ ва мутобиқшавӣ ба фарҳанги мо табдил ефтааст. Мо ба платформае табдил ефтем, ки барои ба даст овардани даромади чандир ва интиқоли одамон ва молҳо бо роҳҳои гуногун имконият фароҳам меорад. Аз якҷоя кардани сафарҳо дар намудҳои гуногуни нақлиет то осон кардани ҳамлу нақли бор, аз расонидани хӯрок ва чизҳои зарурӣ то доруворӣ, мо ниезҳои шуморо шабонарӯзӣ қонеъ карда, имкониятҳои даромадро фароҳам меорем.',
        text6: 'Амният ба туфайли санҷиши ҳамаҷонибаи маълумоти саволнома ва равандҳои санҷиши вақти воқеӣ афзалияти аввалиндараҷаи мо боқӣ мемонад. Дар Gram, ҷустуҷӯи навоварӣ ҳудуд надорад-ин роҳи доимии кашфиет ва бозефт аст.'

      },
      whoWeAre:{
        title1:'Мо кистем?',
        text1:'Мо насли ҷавони рақамии оянда ҳастем, мо навоварон, эҷодкорон, исёнгарон ҳастем ва омодаем барои ҳалли мушкилот ва халалдор кардани вазъи мавқеъ масъулиятро ба дӯш бигирем.',
        title2:'Мо чизе ки машғул ҳастем',
        text2:'Дар асоси таҷрибаи истеъмолкунандагон ва миллиардҳо рақамҳо, мо маҳсулоти инноватсионӣ таҳия мекунем, ки ҳаёти мо ва мизоҷони моро қулай, тезтар ва дастрастар кунем.',
        title3:'Барои ки?',
        text3:'Яке аз принсипҳои мо дастрас будан барои ҳар касе, ки мехоҳад хидмати босифатро бо шартҳои имтиёзнок гирад. Мо барои мамлакати худ, чамъият, хамаи мизочон махсулот меофарем.',
        title4:'Рисолат',
        text4:'Ҳадафи мо эҷод кардани технологияҳои инноватсионӣ барои расонидани хидмати босифат ва зуд, мусоидат ба рушди ҷомеа ва беҳтар кардани зиндагии мардум бо мақсади пешво шудан дар иртибот, логистика ва нақлиет мебошад.',
        title5:'Шиносои',
        text5:'Ширкати аз ҳама ба мизоҷон нигаронидашудаи асри 21 шавед, ки хидматрасонии нақлиёти дастрасро пешкаш кунем.',
        title6:'Арзишҳо',
        text6:'Ҳаёт ҳаракат аст, ҳар рӯз мо бо истифода аз абзорҳои инноватсионӣ, ки вақтро сарфа мекунанд ва ба садҳо хидматрасониро дастрас мекунанд, ба сӯи ҳадафи худ ҳаракат мекунем. Натиҷаҳо дар муддати кӯтоҳ ва бо хароҷоти ҳадди ақал барои расидан ба ҳадафҳои худ то ҳадди имкон! 1-1=2 новобаста аз он ки ҷавоб чӣ гуна аст, аз кори нек наметарсанд.',
        title7:'Максади мо',
        text7:'Дар соҳаи нақлиёт бренди байналмилалӣ эҷод кунем ва то соли 2030 ба хидматрасонии муштариён нигаронидашуда табдил ёбем.',
      },
      ourTeam:{
        title: 'Дастаи мо',
        text1:'Дастаи мо коллективи мута-хассисонест, ки бо максади умуми ва вазифахои гузошташуда муттахид шудаанд. Мо дар ягонагии кор карда, ба хар як фикру андеша ахамият медихем. Мо принципи бовари ва хамфикри, ба хамдигар ёрй расонданро риоя мекунем.',
        text2:'Мо беҳтарин мутахассисони касбу кори гуногунро ба кор мебарем. Ҷавонтарин коргар дар корхонаи мо 17 сол дорад. Барои мо чизи асосӣ на синну сол ва таҷрибаи корӣ, балки орзу ва хоҳиши шумост.',
        text3:'Рушди муттасил калиди муваффақият аст. Мо бо замон ҳамқадам ҳастем: мо дар ҳакатонҳо ширкат мебарем, бо стартапҳо таҷриба мекунем ва барои бачаҳоямон машғулиятҳои ҷолиби эҷоди дастаро мегузаронем. Мо медонем, ки на танҳо кор кардан, балки аз кори ҳаррӯза дам гирифтан низ лозим аст.',
      },
      searchJob:{
        title:'Кори дӯстдоштаро пайдо кунед',
        text:'Ҷустуҷӯи ҷойҳои холии дастрас'
      }
    },
    blogPage:{
      title1:'Блог',
      title2:'Лоиҳаи нави мо - Hamroh',
      text:'Ба куҷое равед, новобаста аз он ки ба кадом макони дурдаст сафар кунед - бо Ҳамроҳ ҳар сафар бехатар хоҳад буд. Шумо ҳамеша ба он ҷо зуд, қулай ва самаранок хоҳед расид!',
      button:'Барои гирифтани маълумоти бештар'
    },
    ridePage:{
      title1:'Ҳамеша сафаре, ',
      title2:'ки шумо мехоҳед',
      text:'Саёҳат фармоиш диҳед, нишинед ва истироҳат кунед.',
      button:'Фармоиш',
      infoBlock:{
        title:'Барои чи барномаи Gram-ро истифода баред?',
        title1:'Тез ва осон',
        text1:'Иҷроиши фармоиш дар якчанд дақиқа',
        title2:'Нархҳои аъло',
        text2:'Мо кушиш мекунем, ки бехтарин нарххоро барои сафар дар шахри шумо пешниход кунем',
        title3:'Қулай',
        text3:'Бо якчанд пахш - ба ҷои лозимиатон расед ',
      },
      downloadGram:{
        title:'Барномаи Gram-ро насб намоед',
        text:'Барномаи Gram ба Шумо имконияти ба ҷои хостаатон рафтанро медиҳад.',
        button:'Насб кардан'
      },
      questionsBlock:{
        title:'Саволҳои маъмул',
        ques1:'Барои фармоиш додани такси чӣ гуна ҳисоб эҷод кардан мумкин аст?',
        answer1:'Барои эҷод кардани ҳисоб, шумо бояд барномаи Gram-ро аз Google Play зеркашӣ кунед е дар сайт сабти ном кунед',
        ques2:'Чӣ тавр фармоиш додани мумкин?',
        answer2:'Барномаро кушоед ё ба вебсайти',
        answer3:'ворид шавед ва макони таъиноти худро ворид кунед. Пас интихоби сафареро, ки ба эҳтиёҷоти шумо мувофиқ аст, интихоб кунед. Фармоиши худро бо пахш кардани тугмаи "Фармон" тасдиқ кунед.',
        ques3:'Метавонам бе барномаи мобилӣ такси фармоиш диҳам',
        answer4:'Бале, шумо метавонед тариқи сомонаи мо ',
        answer5:'ва ё бо рақами кӯтоҳи мо 0220 такси фармоиш диҳед.',
      },
      contactPage:{
        text:'Тоҷикистон, Хуҷанд, хиёбони И.Сомонӣ 46а'
      }
    }
  }
};

const i18n = createI18n({
  locale: 'ru',
  messages
});

export default i18n;
